@import "../../../bps/components/select/select.scss";

.select {
  $root: &;

  &__selected-value {
    font-weight: $fontWeightMedium;
    padding: 8px 35px 0 11px;
    min-width: auto;

    @media (max-width: $screen-tablet-portrait) {
      font-size: $fontSizeSmall;
    }

    .paging__select & {
      > span {
        margin: 0 auto;

        @media (min-width: $screen-tablet-landscape) {
          margin: 0;
        }
      }
    }
  }

  &__label {
    left: 11px;

    &--as-placeholder {
      color: $inputPlaceholderColor;

      @media (max-width: $screen-tablet-portrait) {
        font-size: $fontSizeSmall;
      }
    }
  }

  &__wrapper {
    border-radius: $baseBorderRadius;
    height: 50px;
    border: 1px solid $grayLightColor;

    @media (min-width: $screen-tablet-portrait) {
      height: 45px;
    }

    &::before {
      content: map-get($icons, arrowDownBold);
      transform: translateY(-50%);
      transition: transform .2s;
      font-size: 14px;
    }

    &--active {
      border-radius: $baseBorderRadiusSmall $baseBorderRadiusSmall 0 0;

      &::before {
        transform: translateY(-50%) scaleY(-1);
      }
    }
  }

  &--formless {
    #{$root}__wrapper {
      @media (min-width: $screen-tablet-landscape) {
        padding-right: 20px;
      }

      &::before {
        top: 53%;
        right: 12px;
      }

      #{$root}__selected-value {
        @media (min-width: $screen-mobile) {
          padding-left: 12px;
        }
      }
    }
  }
}
