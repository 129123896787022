@import "../../../bps/components/form/form.scss";

.form {
  &__text {
    font-size: $fontSizeSmall;
    font-weight: $fontWeightThin;
  }

  &__radio-line {
    min-height: 56px;
  }

  .input__label,
  .select__label {
    font-weight: $fontWeightMedium;
  }

  .radio-input__label,
  .checkbox__label {
    font-weight: $fontWeightRegular;
  }

  &__headline {
    font-family: $fontHeadlineFamily;
  }

  &__mandatory-text-block {
    font-size: 14px;
  }

  &__option-area {
    border-radius: 0;

    & + & {
      margin-top: 8px;
    }

    &:has(input:checked) { // stylelint-disable-line
      border-color: $grayColor;
    }
  }
}
